import React, { Component } from "react";
import { Loader, Dimmer } from "semantic-ui-react";

class Loaderapp extends Component {
  state = {};
  render() {
    const { Active } = this.props;
    if (Active)
      return (
        <Dimmer active inverted>
          <Loader content="Loading" />
        </Dimmer>
      );
    else {
      return null;
    }
  }
}

export default Loaderapp;
