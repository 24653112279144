import React from "react";
import { Message } from "semantic-ui-react";

const Alertmsg = ({ classDiv, msg, ...rest }) => {
  return (
    <Message negative className={classDiv}>
      <Message.Header>{msg}</Message.Header>
    </Message>
  );
};

export default Alertmsg;
