import React, { Component } from "react";
import { Grid } from "semantic-ui-react";
import Alertmsg from "./alertmsg";
class ContactAdministrator extends Component {
  render() {
    return (
      <Grid
        textAlign="center"
        style={{ height: `${window.innerHeight}px` }}
        verticalAlign="middle"
      >
        <Grid.Row verticalAlign="top">
          <Grid.Column mobile={16} tablet={8} computer={4}>
            <Alertmsg
              classDiv="Failed"
              msg="Please Contact your administrator to setup the brand details & template."
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default ContactAdministrator;
